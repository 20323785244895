.page-footer {
  background: rgba(0, 0, 0, 0.1) !important;

  li {
    color: #212529;
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease;
    padding: 1rem !important;
    align-items: center;
    margin-bottom: 0.4rem;
    margin-bottom: 0.4rem;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    .badge {
      box-shadow: none;
    }
  }

  img {
    max-height: 150px;
  }

  .footer-copyright {
    background: rgba(0, 0, 0, 0.7) !important;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Christian Aichner
 */
