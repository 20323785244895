body {
  background: #fff;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3f729b;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1c2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}

.sectionPage {
  height: 600px;
  position: relative;
  top: -15px;
  margin-bottom: -100px;
  background-image: url("../../../assets/bg/bg.jpg");
  background-size: cover;
  background-position: center center;
}

.why {
  .col-lg-4 {
    text-align: center;

    img {
      max-height: 100px;
      margin-bottom: 1rem;
    }
  }
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
  border-bottom: 0;
}

.example-components-list li > a .fa {
  color: rgba(0, 0, 0, 0.35);
  float: right;
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Christian Aichner
 */
