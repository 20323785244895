.cookie-modal {
  .form-check-input[type="checkbox"].filled-in:checked + label:after,
  label.btn input[type="checkbox"].filled-in:checked + label:after {
    background-color: #1976d2;
    border: 2px solid #1976d2;
  }
  .form-check-input[type="checkbox"]:disabled:checked + label:after,
  label.btn input[type="checkbox"]:disabled:checked + label:after {
    background-color: lighten(#1976d2, 35%);
    border: 2px solid lighten(#1976d2, 35%);
  }
  .btn {
    i {
      margin-right: 0.3rem;
    }
  }
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Christian Aichner
 */
