.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

// Custom colors
.bg-dark {
  background-color: $dark !important;
  &.darken-1 {
    background-color: darken($dark, 5%) !important;
  }
  &.darken-2 {
    background-color: darken($dark, 10%) !important;
  }
  &.darken-3 {
    background-color: darken($dark, 15%) !important;
  }
  &.lighten-1 {
    background-color: lighten($dark, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($dark, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($dark, 30%) !important;
  }
}

.bg-red {
  background-color: $red !important;
  &.darken-1 {
    background-color: darken($red, 10%) !important;
  }
  &.darken-2 {
    background-color: darken($red, 20%) !important;
  }
  &.darken-3 {
    background-color: darken($red, 30%) !important;
  }
  &.lighten-1 {
    background-color: lighten($red, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($red, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($red, 30%) !important;
  }
}

.bg-green {
  background-color: $green !important;
  &.darken-1 {
    background-color: darken($green, 10%) !important;
  }
  &.darken-2 {
    background-color: darken($green, 20%) !important;
  }
  &.darken-3 {
    background-color: darken($green, 30%) !important;
  }
  &.lighten-1 {
    background-color: lighten($green, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($green, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($green, 30%) !important;
  }
}

// Custom text
.red-text {
  color: $red !important;
}

.pink-text {
  color: $pink !important;
}

.testimonial-card {
  background: darken(white, 3%);
  height: 100%;
}

// Buttons
.btn {
  box-shadow: none !important;

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-snek {
    background: white;

    &:hover {
      background: darken(white, 10%);
    }
  }
}

hr {
  width: 70px;
}

// Cards
.card {
  box-shadow: none;
  border-radius: 0.4rem;
}

// Generic
.clickable {
  cursor: pointer !important;
}

.btn {
  border-radius: 0.4rem;

  i {
    padding-right: 0.2rem;
  }

  &.btn-mars {
    background: #d65048;
    color: white;

    &:hover {
      background: darken(#d65048, 5%) !important;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Christian Aichner
 */
